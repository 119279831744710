import arrowMobile from "../assets/mobile-arrow.svg"
import {Link as Linker} from "react-scroll"
import programmerImg from "../assets/programmer.svg"
export default function FrontPage(){
    
    return (
        
<>
        <img id="#Front-Page" src={programmerImg} alt="front end developer" className="mainimg" />
        <div id="Front-Page">
        <section className="intro-container">
        <h4 data-aos="fade-down"

data-aos-delay="350"
data-aos-duration="1200"
data-aos-easing="easeQuad"
data-aos-anchor-placement="position-title" 
className="introtext-top"> Hi! I'm <span className="name-highlight">
        Kingsley Onwuchekwa
        </span> and I'm a
        </h4>
        <h1 
        data-aos="fade-down"

data-aos-delay="350"
data-aos-duration="1100"
data-aos-easing="easeQuad"
 className="positiontitle">
        <span 
        className="arrow-text">{'<'}</span> Front End Developer <span className="arrow-text">{'/>'}</span>
        </h1>
        <div data-aos="fade-up"

data-aos-delay="350"
data-aos-duration="1100"
data-aos-easing="easeQuad" className="info-wrapper">
<div className="styled-div-btn">
        <Linker to="Projects-Page" spy={true} smooth={true} offset={-70}duration={1000}>
       <div>
        
        SEE MY WORK
        </div>

        </Linker>
        </div>
       
        </div>
        <div className="mouse-container">
		<div className="mouse"></div>
</div>
        <img src={arrowMobile} alt="scroll down" className="scroll" />
        </section>
        </div>
        </>
    )
}