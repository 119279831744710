import Img1 from "../assets/ProjectStudy/img1.jpg";
import Img2 from "../assets/ProjectStudy/img2.jpg";
import Img3 from "../assets/ProjectStudy/img3.jpg";
import Img4 from "../assets/ProjectStudy/img4.jpg";
import Img5 from "../assets/ProjectStudy/img5.jpg";
import Img6 from "../assets/ProjectStudy/img6.jpg";
import Img7 from "../assets/ProjectStudy/img7.jpg";


// array containing nested objects of image paths from assets folder along with image description

 const projStudyImg = [
    {
        img: Img1,
        imgDesc: "Completed Prototype of the Smart Chair side view"
    },
    {
        img: Img2,
        imgDesc: "Completed Prototype of the Smart Chair back view"
    },
    {
        img: Img3,
        imgDesc: "Alignment of the sensors on the chair after bracket installation"
    },
    {
        img: Img4,
        imgDesc: "Prototype testing when user is slouched forwards."
    },
    {
        img: Img5,
        imgDesc: "Prototype testing when user's lower back is not against the backrest."
    },
    {
        img: Img6,
        imgDesc: "Prototype testing when user's torso is leaning to the side while using seat arms for support."
    },
    {
        img: Img7,
        imgDesc: "User maintaining correct posture"
    }
]
export default projStudyImg;
