import { nanoid } from "nanoid";
import ProjStudy from "../assets/site-p1-cover.jpg";
import Img2 from "../assets/img2.jpg";
import Devvity from "../assets/videos/Devvity-Demo.mp4";
const projectData = [
  {
    id: nanoid(14),
    projectImgUrl: Devvity,
    projectTitle: "Devvity Productivity App (FEATURED)",
    projectDescription:
      "All-in-one productivity app  designed with Figma, built with React JS and styled with CSS",
    projectStack: "HTML, CSS, TAILWIND, TYPESCRIPT, NEXTJS, FIGMA",
    projectLink: "https://devvity.vercel.app",
    github: "https://github.com/KingXP-Pythoner/RainChecked-v2.0",
  },
  {
    id: nanoid(13),
    projectImgUrl: Img2,
    projectTitle: "Personal 3D Art Gallery App",
    projectDescription:
      "3D art gallery app to showcase some of my digital artworks over the years. Built with React JS and styled with CSS.",
    projectStack: "HTML, React, CSS",
    projectLink: "https://kingxpression.vercel.app",
    github: "https://github.com/KingXP-Pythoner/Personal-3d-Art-Portfolio",
  },
  //   {
  //     id: nanoid(12),
  //     projectImgUrl: QuizAppCover,
  //     projectTitle: "Quizzed Now! - Trivia Quiz App",
  //     projectDescription:
  //       "Quiz app designed with Figma, built with React JS and styled with CSS, consuming a public Trivia API for questions and answers.",
  //     projectStack: "HTML, CSS, JAVASCRIPT, REACT, FIGMA",
  //     projectLink: "https://quizzednow.vercel.app",
  //     github: "https://github.com/KingXP-Pythoner/QuizzedNow",
  //   },

  {
    id: nanoid(11),
    projectImgUrl: ProjStudy,
    projectTitle: "Final Year Project Study - Smart Ergo Chair",
    projectDescription:
      "Applied IoT to upgrade an ergonomic chair with posture detection capabilities, using Arduino, C++, and Visual Basic.",
    projectStack: "C++, ARDUINO, VISUAL BASIC, IOT",
  },
];
export default projectData;
