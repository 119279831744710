import {Link as Linker, animateScroll as scroll} from "react-scroll"

export default function SideMenu({toggleMenuClose}){
    return (
        <div className="sidemenubox">
        <i onClick={(event)=>toggleMenuClose(event)} className="fa-solid fa-circle-xmark"></i>
            <ul className="navlist-menu">
            <Linker className="sidemenu-item" to="Home-Page" spy={true} smooth={true} offset={-70}duration={1000}>
            <li className="sidemenu-item" onClick={(event)=>toggleMenuClose(event)} >
           Home</li>
            </Linker>
            <Linker to="About-Page" spy={true} smooth={true} offset={-70}duration={1000}>
            <li className="sidemenu-item" onClick={(event)=>toggleMenuClose(event)} >About</li>
            </Linker>
            <Linker to="Projects-Page" spy={true} smooth={true} offset={-70}duration={1000}>
            <li className="sidemenu-item" onClick={(event)=>toggleMenuClose(event)} >Projects</li>
            </Linker>
            <Linker to="Contact-Page" spy={true} smooth={true} offset={-70}duration={1000}>
            <li className="sidemenu-item" onClick={(event)=>toggleMenuClose(event)} >CONTACT ME</li>
            </Linker>
            <a onClick={(event)=>toggleMenuClose(event)} href="https://github.com/KingXP-Pythoner"><li><i className="fa-brands fa-github"></i></li></a>
            <a onClick={(event)=>toggleMenuClose(event)} href="https://linkedin.com/in/kingsley-onwuchekwa"><li><i className="fa-brands fa-linkedin"></i></li></a>
            </ul>
        </div>
    )
}