
import WebLogo from "../assets/DevKingsley-logo.svg"


import { Link as Linker} from "react-scroll";
export default function Navbar( {toggleSideMenu} ) {
    
    return (
        <nav data-aos="zoom-in"

        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out">
      <div id="Navigation Bar" className="navbar">
            <a href="https://devkingsley.com"><img src={WebLogo} alt="DevKingsley's Porfolio" className="web-logo"/>
            </a>
            <ul className="navlist">

            
             <li><Linker to="Home-Page" spy={true} smooth={true} duration={1000}>Home
             </Linker>
            </li>
            
            
<li>
<Linker to="Projects-Page" spy={true} smooth={true}  offset={-70} duration={1000}>Projects
</Linker></li>

            
<li>
<Linker to="About-Page" spy={true} smooth={true} offset={-70}duration={1000}>
About
</Linker>
</li>

           </ul>
           
            <ul className="contactnav">
            

           <li>
           <Linker  to="Contact-Page" spy={true} smooth={true} offset={260}duration={1000}>
            <button className="primary-btn cont-btn">
            CONTACT ME</button>
            </Linker></li>
            
            <a target="_blank" rel="noreferrer"
            href="https://github.com/KingXP-Pythoner"><li>
            <i className="fa-brands fa-github"></i></li></a>
            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/kingsley-onwuchekwa/"><li>
            <i className="fa-brands fa-linkedin"></i></li></a>
           </ul>
           
            <a onClick={()=>toggleSideMenu()} href="#Home-Page">
                 <i  style={{color: "#b18820"}} className="fa-solid fa-bars"></i></a>
            
        </div>
        </nav>
        
    )
}