import React from 'react'
import projStudyImg from "./ProjImages"
import { useState } from 'react'
import DemoVideo from "../assets/ProjectStudy/demo-vid.mp4"
function ProjectStudyPage() {
const [distance, setdistance] = useState(0)



const moveCarousel = (e)=>{
    
    if(e.target.id === "right-move" || e.target.id === "right-move-i"){
    document.querySelector(".wrap-carousel").scrollLeft += 170;
  
    }
    else if(e.target.id === "left-move"|| e.target.id === "left-move-i"){
        document.querySelector(".wrap-carousel").scrollLeft -= 170;
    }
    
}

const stylesCarousel = {
    transform: `translateX(${distance}px)`
}
    return (
        <div className='wrapper-project-study'>



            <header>
                <h1 className='ps-title'>Smart 'Ergo' Chair</h1>
            </header>

            <main>

                <section>

                    <article className='description'>
                        <h2 className='h2'>Project Overview</h2>
                        Final year of university was the most insightful and challenging year of my university life. I was given the opportunity to work on a project with a team of 3 other students.
                        For our project study, we came up with an idea that we thought would absolutely be useful to the masses, especially for people in the workforce. We designed and developed a smart chair
                        that would help people with back pain by forceful posture correction (but not like in a bad way ha-ha). We applied simple IoT engineering to create an automated reminder system that persistently alerts the chair user if their posture goes bad after a period of time. The project was a great learning experience and I was able to develop my technical skills. My love for programming grew twice as much as I did not enjoy
                        being frustrated when I came across obstacles but would be 110% ecstatic after solving the problem just in time for a new obstacle right ahead (life of an engineer, isn't it?).</article>
                </section>
                <section>
                    <br />
                    <article className='description list-desc'>
                        <h2 className='h2'>My Role</h2>
                        <ol>
                            <li>I was the sole programmer for the project. I was responsible for the design of the microcontroller operation logic and programming (Arduino).</li>
                            <li>Development of the GUI app for sending and receiving data from the MC. </li>
                            <li>Quality assessment of electrical connections and components.</li>
                            <li>Calibrating and calculating  sensor input and output data, vital in accurate data gathering and appropriate positioning of the sensors to be installed on the chair.</li>
                        </ol>
                    </article>
                </section>
                <section>
                    <br />
                    <article className="list-flex-box description">
                        <h2 className='h2'>Technologies Used</h2>
                        <div className="wrap-list-box">
                            <div className='list-flex-1'>
                                <h3 className='h3'>Software</h3>

                                <ol>
                                    <li>Arduino IDE</li>
                                    <li>Visual Studio</li>
                                    <li>C++</li>
                                    <li>VB .NET</li>
                                </ol>
                            </div>
                            <div className='list-flex-2'>
                                <h3 className='h3'>Hardware</h3>
                                <ol>
                                    <li>Arduino Mega</li>
                                    <li>Ergonomic Chair</li>
                                    <li>Ultrasonic Sensors</li>
                                    <li>Ultrasonic Sensor brackets</li>
                                    <li>Breadboard</li>
                                    <li>Electrical wires, ...etc.</li>
                                </ol>
                            </div>
                        </div>
                    </article>
                </section>
                <h2 className='h2'>Project Visuals</h2>
{/* create a image carousel of 6 images with buttons that show on hover and when clicked shows a new image */}
                <section className='project-visuals'>
                <div style={stylesCarousel} className="wrap-carousel">
                <div className="paditem"></div>
                   {
                          projStudyImg.map((item, index) => {
                                return (
                                    <div key={index} className='img-container'>
                                    <div className="p-overlay">
                    <div className="p-overlay-text"> {item.imgDesc} </div>
                                        </div>
                                        <img src={item.img} alt={item.imgDesc} className='carousel-item' />
                                        
                                        
                                    </div>
                                )
                            })
                   }
                   <div className="paditem"></div>
                   </div>
                   <div onClick={moveCarousel} id="left-move" className="car-left-btn"><i  id="left-move-i" className="fas fa-chevron-left"></i></div>
                   
                <div onClick={moveCarousel} className="car-right-btn" id="right-move" ><i id="right-move-i"className="fas fa-chevron-right"></i>
                
                </div>
                </section>
                   <section>
                    <br />
                    <article className='description vid-box'>
                        <h2 className='h2'>Video Demo</h2>
                        <video alt="video demo of project" src={DemoVideo} className='video-demo' controls>
                        </video>
                    </article>
                   </section>



            </main>
        </div>
    )
}

export default ProjectStudyPage