import React from "react";
import { useState } from "react";
import About from "./About";
import Contact from "./Contact";
import FrontPage from "./FrontPage";
import Projects from "./Projects";
import Navbar from "./Navbar";
import Experience from "./Experience";
import SideMenu from "./SideMenu";

export default function Home() {
  const [menubar, setmenubar] = useState(false);

  function toggleMenu(event) {
    setmenubar((prev) => !prev);
  }
  return (
    <div>
      {menubar ? <SideMenu toggleMenuClose={toggleMenu} /> : null}
      <Navbar toggleSideMenu={toggleMenu} />
      <FrontPage id="Home-Page" />
      <About id="About-Page" />
      <Experience id="Experience-Page" />
      <Projects id="Projects-Page" />
      <Contact id="Contact-Page" />
    </div>
  );
}
