export default function Experience() {
  return (
    <>
      <section id="Experience-Page" className="experience-section">
        <h2
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1200"
          data-aos-easing="easeQuad"
          className="heading about"
        >
          PROFESSIONAL EXPERIENCE
        </h2>
        <article
          data-aos="slide-up"
          data-aos-delay="350"
          data-aos-duration="1200"
          data-aos-easing="easeQuad"
          data-aos-anchor-placement="heading stack"
          className="experience-article"
        >
          <h3> SEAPLANEGROUP.COM - [Nov 2022]</h3>
          <br />
          <p>
            As the Lead Frontend Developer for an airline charter startup, I
            developed and implemented a responsive and user-friendly website
            using React Next JS, Chakra UI, and Tailwind CSS in a short time. I
            integrated various APIs from both the company and third-party
            providers to enable seamless business and payment transactions.
            Zustand was leveraged to manage the application state, and the
            website was deployed on MongoDB and Express for stability and
            security.
          </p>
          <br />
          <p>
            During my tenure, I successfully led the development and launch of
            the website, resulting in an increase in the company's online
            visibility and user engagement. I also collaborated with other teams
            to ensure the successful delivery of high-quality projects. My
            accomplishments include developing a secure payment system, leading
            the adoption of Zustand, and collaborating with the UX design team
            to create a visually appealing website that was consistent with the
            company's brand.
          </p>
          <br />
          <p>
            Some of my key accomplishments during my time at the airline charter
            startup include:
          </p>
          <ul>
            <li>
              Developing a responsive and user-friendly website that achieved
              high user engagement and retention rates.
            </li>
            <li>
              Implementing multiple secure and robust payment systems such as
              Paypal, Paymongo and so on, that increased the company's revenue.
            </li>
            <li>
              Collaborating with the UX design team to create a visually
              appealing website that was consistent with the company's brand.
            </li>
            <li>
              Leading the adoption of Zustand, as well as global codebase
              refactoring which significantly improved the website's performance
              and reduced bugs.
            </li>
          </ul>
        </article>
      </section>
    </>
  );
}
