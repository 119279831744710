import Home from "./components/Home";
//Use react router version 6 syntax to route to Projects Study Page when a project is clicked in Projects section
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProjectStudyPage from "./components/ProjectStudyPage";
import Navbar from "./components/Navbar";


export default function App() {
  return (
    <div className="App">
        <Router>

      
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/project/1" element={<ProjectStudyPage />} />
        </Routes>
      </Router>

    </div>
  );
}

