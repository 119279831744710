
export default function Contact() {
    let copyrightDate = new Date().getFullYear()


    
    return (
        <section id="Contact-Page" className="contactform-container">
            <h2 className="heading contact-heading">Contact Me</h2>
            
            <div className="form-border">
                <form 
                name="contact" action="/contact" method="POST">
                    <input type="hidden" name="form-name" value="contact" />
                    <input name="name" type="text" placeholder="Enter your name" required/>
                    <input name="email" type="text" placeholder="johndoe@email.com" required/>
                    <input name="subject" type="text" placeholder="Enter a topic"/>
                    <textarea name="message" placeholder="Write me a message..."
                     required>
                 
                    </textarea>
                    <button type="submit" className="sendform"><i className="fa-solid fa-paper-plane"></i> SEND</button>
                    </form>
                    </div>
                    
                    <div className="contactform-icons">
                    <a target="_blank" rel="noreferrer" href="https://github.com/KingXP-Pythoner">
                    <i className="fa-brands fa-github"></i>
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/kingsley-onwuchekwa/">
                     <i className="fa-brands fa-linkedin"></i>
                     </a>
                    
                    </div>
                    <p className="email-msg">Email: kingsleykelechi008@gmail.com</p>
                    <footer>© {copyrightDate} Dev<span className="footer-logo">Kingsley.</span>  <br /> Developed by Kingsley Onwuchekwa.</footer>
        </section>

    )
}