import projectData from "./projectData";
// create router link for each project in projectData
import { Link } from "react-router-dom";
export default function Project() {
  return (
    <section id="Projects-Page">
      <h2
        data-aos="slide-up"
        data-aos-delay="50"
        data-aos-duration="1200"
        data-aos-easing="easeQuad"
        className="heading projects"
      >
        My Projects
      </h2>
      <div className="wrap-project">
        {/* {projectElements} */}
        <div className="project-cont-wrap">
          <a href="https://devvity.vercel.app" rel="noreferrer" target="_blank">
            <div className="project-container featured">
              <video controls className="project-video" loop autoPlay muted>
                <source src={projectData[0].projectImgUrl} type="video/mp4" />
              </video>
              <div className="project-title">{projectData[0].projectTitle}</div>
            </div>
          </a>
          <div className="view-live-container">
            <div className="proj-btns">
              <a
                href={projectData[0].projectLink}
                rel="noreferrer"
                target="_blank"
              >
                Demo
              </a>
            </div>
            <div className="code">
              <a href={projectData[0].github} rel="noreferrer" target="_blank">
                {/* github font awesome */}
                <i className="fa-brands fa-github"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="project-cont-wrap">
          <a
            href="https://kingxpression.vercel.app"
            rel="noreferrer"
            target="_blank"
          >
            <div className="project-container">
              <img
                src={projectData[1].projectImgUrl}
                alt="Quiz app called Quizzed Now"
                className="project-img"
              />
              <div className="p-overlay">
                <div className="p-overlay-text">
                  {projectData[1].projectDescription}
                </div>
              </div>
              <div className="project-title">{projectData[1].projectTitle}</div>
            </div>
          </a>
          <div className="view-live-container">
            <div className="proj-btns">
              <a
                href={projectData[1].projectLink}
                rel="noreferrer"
                target="_blank"
              >
                Demo
              </a>
            </div>
            <div className="code">
              <a href={projectData[1].github} rel="noreferrer" target="_blank">
                {/* github font awesome */}
                <i className="fa-brands fa-github"></i>
              </a>
            </div>
          </div>
        </div>

        <Link to="/project/1" target="_blank">
          <div className="project-container">
            <img
              src={projectData[2].projectImgUrl}
              alt="automated ergonomic chair project"
              className="project-img"
            />
            <div className="p-overlay">
              <div className="p-overlay-text">
                {projectData[2].projectDescription}
              </div>
            </div>
            <div className="project-title">{projectData[2].projectTitle}</div>
          </div>
        </Link>
      </div>
    </section>
  );
}
